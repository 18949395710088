<template>
  <OddsCompanyFilter
    v-if="isMobile && isCompanyFilter"
    @closeFilter="companyFilterHandler"
    @companyGroupSelectHandler="companyGroupSelectHandler"
    :companyListOptions="companyListOptions"
    :isCompanyFilter="isCompanyFilter"
    :companyGroupSelected="companyGroupSelected"
  />
  <!-- Mobile Match Selector -->
  <div class="matchList-tab " v-if="isMobile">
    <button
      v-for="(o, idx) in tableSelector"
      :key="idx"
      @click="selectTable(o)"
      :class="{ 'tab-selected': isTable === o }"
    >
      {{ $t(o) }}
    </button>
  </div>
  <div class="matchList-tab display-flex-sb " v-if="isMobile">
    <div class="display-flex-center">
      <div class="nav-pill" id="periodTab" v-show="isTable === 'COMPANY'">
        <span class="mr-03rem">{{ $t(periodSelected.value) }}</span>
        <i class="svg-arrow__vertical ">
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            class="league-dropdown__svg"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z"
              fill="inherit"
            />
          </svg>
        </i>
      </div>
      <div
        class="nav-pill"
        v-for="o in tabSelector"
        :key="o"
        :class="{ active: isTab === o }"
        @click="selectTab(o)"
      >
        <span>{{ $t(o) }}</span>
      </div>
    </div>

    <div
      class="nav-pill nav-pill-filter"
      @click="companyFilterHandler"
      v-show="isTable === 'COMPANY'"
    >
      <div class="filter-wrapper">
        <img src="../../../../static/images/icons/icon_filter_float.png" />
      </div>
    </div>
  </div>
  <section class="matchList-container">
    <div class="matchList-content-header" v-if="!isMobile">
      <div class="matchList-content_title">{{ $t("EUROPE_ODDS") }}</div>
      <div class="navtab-wrapper">
        <div class="nav-pill" @click="periodShowHandler">
          <span class="mr-03rem">{{ $t(periodSelected.value) }}</span>
          <i class="svg-arrow__vertical ">
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              class="league-dropdown__svg"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z"
                fill="inherit"
              />
            </svg>
          </i>
        </div>
        <div class="dropdown-wrapper" :class="{ selected: isPeriodShow }">
          <div class="ps ps--active-x ps--active-y">
            <div class="dropdown-container__wrapper">
              <div
                class="dropdown-container"
                v-for="l in periodLabel"
                :key="l.value"
                @click="periodHandler(l)"
              >
                <div class="dropdown-container__name">
                  <span>{{ $t(l.value) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-pill" @click="companyFilterHandler">
          <span>{{ $t("CHOOSE_A_COMPANY") }}</span>
        </div>
        <OddsCompanyFilter
          v-if="isCompanyFilter"
          @closeFilter="companyFilterHandler"
          @companyGroupSelectHandler="companyGroupSelectHandler"
          :companyListOptions="companyListOptions"
          :companyGroupSelected="companyGroupSelected"
        />
      </div>
    </div>
    <div class="matchList-content_wrapper">
      <table class="matchList-content_table table-full" v-if="isMobile">
        <thead>
          <tr>
            <th :colspan="colSpan" class="matchList-content_table-title">
              {{ $t(isTab) }}
            </th>
          </tr>
          <tr v-if="isTab === 'WIN_INDEX'">
            <th>
              {{ isTable === "COMPANY" ? $t("COMPANY") : null }}
            </th>
            <th v-for="o in headerLabel2.slice(0, 3)" :key="o">{{ $t(o) }}</th>
            <th v-if="isTable === 'COMPANY'">
              {{ $t(headerLabel[headerLabel.length - 1]) }}
            </th>
          </tr>
          <tr v-if="isTab === 'WIN_RATE'">
            <th>
              {{ isTable === "COMPANY" ? $t("COMPANY") : null }}
            </th>
            <th v-for="o in headerLabel.slice(4, 8)" :key="o">{{ $t(o) }}</th>
            <th v-if="isTable === 'COMPANY'">
              {{ $t(headerLabel[headerLabel.length - 1]) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isTable === 'COMPANY'">
            <template v-for="c in selectedResult" :key="c.companyId">
              <template v-if="periodSelected.name !== 'all'">
                <tr>
                  <td>{{ $t(companyName(c.companyId)) }}</td>
                  <template v-if="isTab === 'WIN_INDEX'">
                    <td v-for="list in oddsList" :key="list">
                      {{
                        c[periodSelected.name][list]
                          ? c[periodSelected.name][list]
                          : "-"
                      }}
                    </td>
                  </template>
                  <template v-else-if="isTab === 'WIN_RATE'">
                    <td v-for="list in rateList" :key="list">
                      {{
                        c[periodSelected.name][list]
                          ? c[periodSelected.name][list]
                          : "-"
                      }}
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      -
                    </td>
                  </template>
                  <td>{{ timeHandler(c.modifyTime) }}</td>
                </tr>
              </template>
              <template v-else>
                <template v-if="isTab === 'WIN_INDEX'">
                  <tr>
                    <td :rowspan="2">{{ $t(companyName(c.companyId)) }}</td>
                    <td v-for="list in oddsList" :key="list">
                      {{ c["initOdds"][list] ? c["initOdds"][list] : "-" }}
                    </td>
                    <td :rowspan="2">{{ timeHandler(c.modifyTime) }}</td>
                  </tr>
                  <tr>
                    <td v-for="list in oddsList" :key="list">
                      {{ c["finalOdds"][list] ? c["finalOdds"][list] : "-" }}
                    </td>
                  </tr>
                </template>
                <template v-else-if="isTab === 'WIN_RATE'">
                  <tr>
                    <td :rowspan="2">{{ $t(companyName(c.companyId)) }}</td>
                    <td v-for="list in rateList" :key="list">
                      {{ c["initOdds"][list] ? c["initOdds"][list] : "-" }}
                    </td>
                    <td :rowspan="2">{{ timeHandler(c.modifyTime) }}</td>
                  </tr>
                  <tr>
                    <td v-for="list in rateList" :key="list">
                      {{ c["finalOdds"][list] ? c["finalOdds"][list] : "-" }}
                    </td>
                  </tr>
                </template>
              </template>
            </template>
            <template v-if="selectedResult.length === 0">
              <tr>
                <td :colspan="colSpan">{{ $t("NO_DATA") }}</td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr v-for="(r, i) in Object.keys(result).slice(1, 5)" :key="i">
              <td>{{ $t(maxMinLabel[i]) }}</td>
              <template v-if="isTab === 'WIN_INDEX'">
                <td v-for="(o, ix) in oddsList" :key="ix">
                  {{ result[r][o] ? result[r][o] : "-" }}
                </td>
              </template>
              <template v-else-if="isTab === 'WIN_RATE'">
                <td v-for="list in rateList" :key="list">
                  {{ result[r][list] ? result[r][list] : "-" }}
                </td>
              </template>
              <template v-else>
                <td>
                  -
                </td>
              </template>
            </tr>
          </template>
        </tbody>

        <tfoot>
          <tr>
            <td :colspan="colSpan"></td>
          </tr>
        </tfoot>
      </table>
      <div class="table-wrapper" v-else>
        <div
          class="table-scrollable"
          id="scrollable"
          :class="{ 'scrollbar-show': tableHeight >= 480 }"
        >
          <table class="matchList-content_table table-full">
            <thead>
              <tr>
                <th v-for="(l, i) in headerLabel" :key="i">
                  {{ $t(l) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="c in selectedResult" :key="c.companyId">
                <template v-if="periodSelected.name !== 'all'">
                  <tr>
                    <td>
                      {{ $t(companyName(c.companyId)) }}
                    </td>
                    <td v-for="(o, i) in c[periodSelected.name]" :key="i">
                      {{ o ? o : "-" }}
                    </td>
                    <!-- <td>{{ c.kellyOdds ? c.kellyOdds : "-" }}</td> -->
                    <td :rowSpan="rowSpan">{{ timeHandler(c.modifyTime) }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td :rowspan="2">
                      {{ $t(companyName(c.companyId)) }}
                    </td>
                    <td v-for="(o, i) in c.initOdds" :key="i">
                      {{ o ? o : "-" }}
                    </td>

                    <!-- <td>{{ c.kellyOdds ? c.kellyOdds : "-" }}</td> -->
                    <td :rowSpan="2">{{ timeHandler(c.modifyTime) }}</td>
                  </tr>
                  <tr>
                    <td v-for="(o, i) in c.finalOdds" :key="i">
                      {{ o ? o : "-" }}
                    </td>
                  </tr>
                </template>
              </template>
              <template v-if="selectedResult.length === 0">
                <tr>
                  <td :colspan="9">{{ $t("NO_DATA") }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <table class="matchList-content_table table-full matchList-value-row">
          <tbody>
            <tr>
              <th></th>
              <th v-for="(l, i) in headerLabel2" :key="i">
                {{ $t(l) }}
              </th>
            </tr>
            <tr>
              <td>{{ $t(maxMinLabel[0]) }}</td>
              <td v-for="o in result.largeInitOdds" :key="o">
                {{ o ? o : "-" }}
              </td>
              <!-- <td :colspan="2" :rowspan="2">-</td> -->
            </tr>
            <tr>
              <td>{{ $t(maxMinLabel[1]) }}</td>
              <td v-for="o in result.largeFinalOdds" :key="o">
                {{ o ? o : "-" }}
              </td>
            </tr>
            <tr>
              <td>{{ $t(maxMinLabel[2]) }}</td>
              <td v-for="o in result.smallInitOdds" :key="o">
                {{ o ? o : "-" }}
              </td>
              <!-- <td :colspan="2" :rowspan="2">-</td> -->
            </tr>
            <tr>
              <td>{{ $t(maxMinLabel[3]) }}</td>
              <td v-for="o in result.smallFinalOdds" :key="o">
                {{ o ? o : "-" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import MobileSelect from "@/lib/mobile-select/mobile-select";
import OddsCompanyFilter from "../../layer/OddsCompanyFilter.vue";
export default {
  props: ["isCompare", "matchCompare", "result"],
  data() {
    return {
      headerLabel: [
        "ALL_COMPANY",
        "HOME_WIN",
        "DRAW",
        "AWAY_WIN",
        "MAIN_WIN_RATE",
        "DRAW_RATE",
        "AWAY_WIN_RATE",
        "RETURN_RATE",
        // "凯利指数",
        "TIME_CHANGE",
      ],
      headerLabel2: [
        "HOME",
        "DRAW",
        "AWAY",
        "MAIN_WIN_RATE",
        "DRAW_RATE",
        "AWAY_WIN_RATE",
        "RETURN_RATE",
        // "凯利指数",
      ],
      oddsList: ["odds1", "odds2", "odds3"],
      rateList: ["winRate", "drawRate", "loseRate", "returnRate"],
      maxMinLabel: [
        "INIT_MAX_VALUE",
        "LIVE_MAX_VALUE",
        "INIT_MIN_VALUE",
        "LIVE_MIN_VALUE",
      ],
      maxMinList: [
        "largeInitOdds",
        "largeFinalOdds",
        "smallInitOdds",
        "smallFinalOdds",
      ],
      periodLabel: [
        { value: this.$i18n.t("START/END"), name: "all" },
        { value: this.$i18n.t("START"), name: "initOdds" },
        { value: this.$i18n.t("END"), name: "finalOdds" },
      ],
      tableSelector: ["COMPANY", "HIGHEST_MIN_VALUE"],
      tabSelector: [
        "WIN_INDEX",
        "WIN_RATE",
        // "凯利指数"
      ],
      isMatchTime: { name: "START", value: "initOdds" },
      isTable: "COMPANY",
      isTab: "WIN_INDEX",
      isCompany: [],
      isCompanyFilter: false,
      isPeriodShow: false,
      companyGroupSelected: [],
      periodSelected: { value: "START/END", name: "all" },
      msInstance: null,
      selectedVal: "",
      tableHeight: "",
    };
  },
  mounted() {
    this.mobileSelector();
    !this.isMobile && this.tableHeightHandler();
    // console.log(this.result);

    for (let i = 0; i <this.companyGroup.length; i++) {
      if (this.companyGroup[i].id=== 1001) {
        this.companyGroup[i].name = this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet"
      }
    }
    if (this.companyGroup.filter(e => e.id === 1).length > 0) {
      /* vendors contains the element we're looking for */
    } else {
     this.companyGroup.push({ name: "MACAUSLOT", id: 1 });

    }
  },
  computed: {
    ...mapGetters(["isMobile", "companyGroup", "currentCountryObj"]),
    colSpan() {
      if (this.isTable === "COMPANY") {
        return this.isTab === "WIN_RATE"
          ? 6
          : this.isTab === "WIN_INDEX"
          ? 5
          : 3;
      } else
        return this.isTab === "WIN_RATE"
          ? 5
          : this.isTab === "WIN_INDEX"
          ? 4
          : 2;
    },
    selectedResult() {
      if (this.companyGroupSelected.length > 0) {
        return this.result.companyOddsList.filter((res) => {
          return this.companyGroupSelected.find((comp) => {
            return comp.id === res.companyId;
          });
        });
      } else return this.result.companyOddsList;
    },
    companyListOptions() {
      // let sortedCompanyGroup = this.companyGroup.sort((a, b) => {
      //   return a.id - b.id;
      // });
       let sortedCompanyGroup = this.companyGroup;
      // sortedCompanyGroup.unshift(sortedCompanyGroup.pop());

      return sortedCompanyGroup.filter((comp) => {
        return this.result.companyOddsList.find((res) => {
          return comp.id === res.companyId;
        });
      });
    },
  },
  watch: {
    // isCompanyFilter: {
    //   handler() {
    //     this.tableHeightHandler();
    //   },
    // },
    periodSelected: {
      handler() {
        this.tableHeightHandler();
      },
    },
  },
  methods: {
    mobileSelector() {
      // this.$nextTick(() => {
      if (this.isMobile) {
        let that = this;

        let triggerId = "#periodTab";
        let title = this.$i18n.t("SELECT_GROUP");
        let ensureBtnText = this.$i18n.t("DONE");
        let cancelBtnText = this.$i18n.t("CANCEL");

        let ensureBtnColor = "#01c3f7";
        let titleBgColor = "#F0F0F0";

        this.msInstance = new MobileSelect({
          trigger: triggerId,
          title: title,
          ensureBtnText: ensureBtnText,
          cancelBtnText: cancelBtnText,
          ensureBtnColor: ensureBtnColor,
          titleBgColor: titleBgColor,
          triggerDisplayData: false,
          wheels: [{ data: this.periodLabel }],

          callback: function(indexArr, data) {
            // this.periodSelected = data[0];
            that.periodHandler(data[0]);
          },
        });
      }
      // });
    },
    tableHeightHandler() {
      this.$nextTick(() => {
        this.tableHeight = document.getElementById("scrollable").offsetHeight;
      });
    },
    expandHandler(data) {
      if (this.isCompany.includes(data)) {
        this.isCompany = this.isCompany.filter((o) => {
          return o !== data;
        });
      } else this.isCompany.push(data);
    },
    selectMatchTime(data) {
      this.isMatchTime = data;
    },
    selectTable(data) {
      this.isTable = data;
    },
    selectTab(data) {
      this.isTab = data;
    },
    timeHandler(date) {
      return moment(date)
        .utc()
        .format("DD-MM   HH:mm");
    },
    companyFilterHandler() {
      this.isCompanyFilter = !this.isCompanyFilter;
    },
    periodShowHandler() {
      this.isPeriodShow = !this.isPeriodShow;
    },
    periodHandler(data) {
      this.periodSelected = data;
      if (!this.isMobile) {
        this.isPeriodShow = !this.isPeriodShow;
      }
    },
    companyGroupSelectHandler(data) {
      this.tableHeightHandler();
      this.companyGroupSelected = data;
    },
    companyName(id) {
      switch (id) {
        case 1001:
          return this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet";
        case 1:
          return "MACAUSLOT";
        case 3:
          return "SINGBET";
        case 4:
          return "LADBROKES";
        case 7:
          return "SNAI";
        case 8:
          return "BET365";
        case 9:
          return "WILLIAMHILL";
        case 12:
          return "YSB";
        case 14:
          return "BETVICTOR";
        case 17:
          return "MANSION88";
        case 19:
          return "INTERWETTEN";
        case 22:
          return "10BET";
        case 23:
          return "188BET";
        case 24:
          return "12BET";
        case 31:
          return "SBOBET";
        case 35:
          return "WANBET";
        case 42:
          return "18BET";
        case 45:
          return "MANBETX";
        case 47:
          return "PINNACLE";
        default:
          return "-";
      }
    },
  },
  components: { OddsCompanyFilter },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
}
.matchList-content_table-subtitle th:nth-of-type(1) {
  border-right: 0;
}
.matchList-content_table-subtitle th:nth-of-type(2) {
  border-left: 0;
}
.matchList-content_table td {
  border: 0.5px solid #4b616c;
  padding: 0.375rem 0;
}
.matchList-content_table th {
  border: 0.5px solid #4b616c;
  padding: 0.625rem 0;
}
.matchList-highlighted td {
  padding: 0.75rem 0;
}
.matchList-content_wrapper {
  flex-direction: column;
}
th {
  font-size: 0.75rem;
  line-height: 1.15rem;
  width: 99px;
  padding: 0.625rem 0;
}
th:nth-of-type(1) {
  width: 299px !important;
}
.matchList-value-row th {
  background: #5c7784;
}
.matchList-value-row th:last-of-type {
  width: 200px;
}
.table-scrollable {
  max-height: 30rem;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  /* width: 100%; */
  /* width: calc(100% - 0.5rem); */
}
.scrollbar-show {
  margin-right: -1rem;
  padding-right: 0.5rem;
  /* width: calc(100% + 0.5rem); */
}
.table-scrollable th {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #35454d;
}
/* scrollbar */
.table-scrollable::-webkit-scrollbar {
  width: 0.5rem;
  position: absolute;
  right: -1rem;
}

.table-scrollable::-webkit-scrollbar-track {
  background: #212b30;
  border-radius: 4px;
}

.table-scrollable::-webkit-scrollbar-thumb {
  background: #4b616c;
  border-radius: 4px;
}
.navtab-wrapper {
  position: relative;
  display: flex;
}
.league-dropdown__svg {
  fill: white;
}
.nav-pill:hover .league-dropdown__svg {
  fill: #000;
}
.dropdown-wrapper {
  left: 0;
  min-width: 6.625rem;
}
@media (max-width: 768px) {
  .no-border {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .matchList-tab {
    margin: 1rem 0.8rem;
    align-items: center;
  }
  .nav-pill:hover,
  .active {
    background-color: var(--color-hover);
    border: 0.1rem solid var(--color-hover);
    color: black;
    font-weight: 700;
  }
  .matchList-content_table td,
  .matchList-content_table th {
    border: 0.75px solid #4b616c;
  }
  th {
    font-size: 0.75rem;
  }
  .filter-wrapper {
    width: 0.75rem;
    height: 0.75rem;
  }
  .nav-pill-filter {
    padding: 0.375rem 1rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
