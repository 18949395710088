<template>
  <div class="company-filter-container">
    <template v-if="isMobile">
      <div class="nav-header">
        <div class="nav-header__back" @click="$emit('closeFilter')">
          <img src="../../../static/images/icons/icon_arrow_left.png" />
        </div>
        <div class="nav-header__title flex-col">
          <span class="bold">{{ $t("CHOOSE_COMPANY") }}</span>
        </div>
        <div class="nav-header__more" @click="confirmCompanyHandler">
          <img
            :src="
              companyGroupList.length > 0
                ? require('../../../static/images/icons/icon_tick_selected.png')
                : require('../../../static/images/icons/icon_tick_unselect.png')
            "
          />
        </div>
      </div>
      <div class="company-filter_options-wrapper">
        <div class="company-filter_bundle-wrapper">
          <div
            class="nav-pill"
            v-for="o in bundleSelector"
            :key="o"
            @click="companySelector(o)"
          >
            <span>{{ $t(o) }}</span>
          </div>
        </div>
        <div class="search-wrapper">
          <img
            class="search-icon"
            src="../../../static/images/icons/icon_search.png"
            alt=""
          />
          <input
            class="search-input"
            type="text"
            :placeholder="$t('SEARCH_COMPANY')"
            @input="filterByInput"
            autofocus
          />
        </div>
        <div class="company-filter_group-wrapper">
          <div
            class="matchList-checkbox_wrapper"
            v-for="o in companyListOptionsFiltered"
            :key="o.id"
            @click="companySelector(o)"
          >
            <div class="display-flex-center">
              <div
                class="matchList-checkbox_box"
                :class="{
                  selected: companyGroupList.find(({ id }) => id === o.id),
                }"
              ></div>
              <span>{{ o.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="company-filter_close" @click="$emit('closeFilter')">
        <img src="../../../static/images/subscribe_close.png" alt="" />
      </div>
      <div class="company-filter_title">{{ $t("CHOOSE_COMPANY") }}</div>
      <div class="search-wrapper">
        <img
          class="search-icon"
          src="../../../static/images/icons/icon_search.png"
          alt=""
        />
        <input
          class="search-input"
          type="text"
          :placeholder="$t('SEARCH_COMPANY')"
          @input="filterByInput"
          autofocus
        />
      </div>
      <div class="company-filter_bundle-wrapper">
        <div
          class="nav-pill"
          v-for="o in bundleSelector"
          :key="o"
          @click="companySelector(o)"
        >
          <span>{{ $t(o) }}</span>
        </div>
      </div>
      <div class="company-filter_group-wrapper" id="scrollable">
        <div
          class="matchList-checkbox_wrapper"
          v-for="o in companyListOptionsFiltered"
          :key="o.id"
          @click="companySelector(o)"
        >
          <div class="display-flex-center">
            <div
              class="matchList-checkbox_box"
              :class="{
                selected: companyGroupList.find(({ id }) => id === o.id),
              }"
            ></div>
            <span>{{ o.name }}</span>
          </div>
        </div>
      </div>
      <div class="company-filter_confirm" @click="confirmCompanyHandler">
        {{ $t("CONFIRM") }}
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: [
    "companyListOptions",
    "isCompanyFilter",
    "companyGroupSelectHandler",
    "companyGroupSelected",
  ],
  data() {
    return {
      bundleSelector: ["CLEAR", "SELECT_ALL"],
      companyGroupList: [],
      tempCompany: [],
      enteredInput: "",
      companyListOptionsFiltered: [...this.companyListOptions],
    };
  },
  mounted() {
    if (this.isMobile) {
      document.body.className = "hidden";
    }
    this.companyGroupSelected
      ? this.companyGroupList.push(...this.companyGroupSelected)
      : (this.companyGroupList = []);

    for (let i = 0; i <this.companyGroup.length; i++) {
      if (this.companyGroup[i].id=== 1001) {
        this.companyGroup[i].name = this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet"
      }
    }
  },
  beforeUnmount() {
    document.body.className = "unset";
  },
  computed: {
    ...mapGetters(["companyGroup", "isMobile","currentCountryObj"]),
  },
  methods: {
    ...mapActions(["companyGroupSelector"]),

    confirmCompanyHandler() {
      this.$emit("closeFilter");
      this.$emit("companyGroupSelectHandler", this.companyGroupList);
      document.body.className = "unset";
    },
    companySelector(data) {
      if (data === "CLEAR") {
        this.companyGroupList = [];
      } else if (data === "SELECT_ALL") {
        this.companyGroupList = this.companyGroup;
      } else if (this.companyGroupList.find(({ id }) => id === data.id)) {
        this.companyGroupList = this.companyGroupList.filter((comp) => {
          return comp.id !== data.id;
        });
      } else this.companyGroupList.push(data);
    },
    filterByInput(e) {
      e.target.value
        ? (this.companyListOptionsFiltered = this.companyListOptions.filter(
            (o) => {
              return o.name
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            }
          ))
        : (this.companyListOptionsFiltered = [...this.companyListOptions]);
    },
  },
};
</script>

<style scoped>
.company-filter-container {
  position: absolute;
  top: 30px;
  right: 0;
  width: 29rem;
  background: #4b616c;
  border-radius: 8px;
  padding: 1.125rem 3rem 3rem 3rem;
  z-index: 5;
}
.company-filter_close {
  width: 100%;
  height: 1.5rem;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}
.company-filter_title {
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
}
.search-wrapper {
  padding: 0.625rem 0.75rem;
  width: 95%;
  margin: 0.625rem auto;
}
.company-filter_bundle-wrapper {
  display: flex;
  align-items: center;
  margin: 0.625rem auto;
}
.company-filter_group-wrapper {
  max-height: 12rem;
  overflow-y: auto;
  margin: 0.625rem auto;
}
.matchList-checkbox_wrapper {
  padding: 0.375rem 0;
  width: 100%;
}
.company-filter_group-wrapper::-webkit-scrollbar {
  width: 0.5rem;
  position: absolute;
  right: -1rem;
}

.company-filter_group-wrapper::-webkit-scrollbar-track {
  background: #6f8c9b;
  border-radius: 4px;
}

.company-filter_group-wrapper::-webkit-scrollbar-thumb {
  background: #212b30;
  border-radius: 4px;
}
.company-filter_confirm {
  width: 95%;
  background: #01c3f7;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #000;
  font-weight: 700;
  text-align: center;
  padding: 0.625rem;
  cursor: pointer;
}
.display-flex-center {
  cursor: pointer;
}
@media (max-width: 768px) {
  .company-filter-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #212b30;
    border-radius: 8px;
    padding: 0;
    z-index: 10;
    width: 100%;
    overflow-y: auto;
  }
  .company-filter_options-wrapper {
    padding: 0 1rem;
  }
  .company-filter_bundle-wrapper {
    margin: 1rem auto;
  }
  .company-filter_bundle-wrapper .nav-pill {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .nav-header {
    background: unset;
    position: relative;
  }
  .nav-header__more {
    background: unset;
    padding-right: 1rem;
  }
  .nav-header__right {
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 3rem;
    height: 100%;
    display: flex;
    background-size: inherit;
  }
  .nav-pill:hover {
    background-color: var(--color-hover);
    border: 0.1rem solid var(--color-hover);
    color: black;
    font-weight: 700;
  }
  .company-filter_group-wrapper {
    max-height: unset;
    overflow: visible;
    margin: 0.625rem auto;
  }
  .matchList-checkbox_wrapper {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    margin: unset;
    max-width: unset;
    border-bottom: 1px solid #35454d;
    padding: 0.75rem 0;
  }
}
</style>
